.main-container {
  padding: 1rem 0; }

.nav-dropdown a {
  color: inherit !important; }

.sign-in-form {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  max-width: 100%; }
  .sign-in-form svg {
    width: 10rem;
    height: 10rem;
    display: block;
    margin: 0 auto; }

.sign-out-link {
  cursor: pointer; }

.homepage-grid-menu {
  min-height: 300px;
  margin-top: 0 !important; }
  .homepage-grid-menu a {
    display: block;
    height: 100%; }
  .homepage-grid-menu .big-icon {
    display: block;
    margin: 0 auto 1rem; }

.pageHeader {
  background: #f2f2f2 !important;
  margin-top: -1rem !important; }
  .pageHeader .separator {
    margin: 0 1rem; }

.copy-to-clipboard-icon {
  margin-left: 0.5rem !important;
  cursor: pointer; }

.password-reset {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  max-width: 100%; }
  .password-reset .header {
    font-size: 2rem !important;
    margin-bottom: 1em !important; }
  .password-reset label {
    margin-bottom: 1em !important; }

.hidden-field {
  display: none; }

.no-bottom-margin {
  margin-bottom: 0 !important; }

.email-search-error {
  text-align: left !important;
  margin-bottom: 3em !important; }

.roles-form .field {
  display: inline-block;
  margin-top: 1px !important; }

.roles-form .role-checkbox {
  margin-right: 1em; }

.roles-form label {
  vertical-align: top; }

.forgot-password {
  margin-top: 1em; }
  .forgot-password a:hover {
    text-decoration: underline; }
